import React from 'react'
import WelcomeIcon from '@mui/icons-material/EmojiObjectsRounded'
import GettingStartedIcon from '@mui/icons-material/PlayArrowRounded'
import InstallationIcon from '@mui/icons-material/GetAppRounded'
import AuthenticationIcon from '@mui/icons-material/VerifiedUserRounded'
import UsageIcon from '@mui/icons-material/CodeRounded'
import EndpointsIcon from '@mui/icons-material/ApiRounded'
import TypesIcon from '@mui/icons-material/CategoryRounded'

import gettingStartedMD from './_pages/getting-started/index.md'
import authenticationMD from './_pages/getting-started/authentication.md'
import widgetSetUpMD from './_pages/getting-started/widget-set-up.md'
import capturingTemplateFieldsMD from './_pages/getting-started/capturing-template-fields.md'
import workflowMD from './_pages/getting-started/workflow.md'
import apiKeysMD from './_pages/getting-started/api-keys.md'
import contextMD from './_pages/getting-started/context.md'
import welcomeMD from './_pages/welcome.md'

import widgetMethodsMD from './_pages/widget-methods/index.md'
import showMethodMD from './_pages/widget-methods/show.md'
import hideMethodMD from './_pages/widget-methods/hide.md'
import openMethodMD from './_pages/widget-methods/open.md'
import closeMethodMD from './_pages/widget-methods/close.md'
import authenticateMethodMD from './_pages/widget-methods/authenticate.md'
import setContextMethodMD from './_pages/widget-methods/set-context.md'
import setTranscriptMethodMD from './_pages/widget-methods/set-transcript.md'
import addEventListenerMethodMD from './_pages/widget-methods/add-event-listener.md'
import removeEventListenerMethodMD from './_pages/widget-methods/remove-event-listener.md'
import removeAllListenersMethodMD from './_pages/widget-methods/remove-all-listeners.md'
import resetStateMethodMD from './_pages/widget-methods/reset-state.md'
import resetAuthMethodMD from './_pages/widget-methods/reset-auth.md'

export const removeFirstAndLastLines = (str: string) => {
  const lines = str.split('\n')
  lines.shift()
  lines.pop()
  return lines.join('\n')
}

export type NavItem = {
  title: string
  icon?: React.ReactNode
  href: string
  children?: NavItem[]
  md?: string
}

export const NAV_ITEMS: NavItem[] = [
  {
    title: 'Welcome',
    icon: <WelcomeIcon />,
    href: '/docs/welcome',
    md: welcomeMD,
  },
  {
    title: 'Getting Started',
    href: '/docs/getting-started',
    md: gettingStartedMD,
    children: [
      {
        title: 'API Keys',
        href: '/docs/getting-started/api-keys',
        md: apiKeysMD,
      },
      {
        title: 'Authentication',
        href: '/docs/getting-started/authentication',
        md: authenticationMD,
      },
      {
        title: 'Widget Set Up',
        href: '/docs/getting-started/widget-set-up',
        md: widgetSetUpMD,
      },
      {
        title: 'Capturing Fields',
        href: '/docs/getting-started/capturing-template-fields',
        md: capturingTemplateFieldsMD,
      },
      {
        title: 'Workflow',
        href: '/docs/getting-started/workflow',
        md: workflowMD,
      },
      {
        title: 'Context',
        href: '/docs/getting-started/context',
        md: contextMD,
      },
    ],
  },
  {
    title: 'Widget Methods',
    href: '/docs/widget-methods',
    md: widgetMethodsMD,
    children: [
      {
        title: 'show',
        href: '/docs/widget-methods/show',
        md: showMethodMD,
      },
      {
        title: 'hide',
        href: '/docs/widget-methods/hide',
        md: hideMethodMD,
      },
      {
        title: 'open',
        href: '/docs/widget-methods/open',
        md: openMethodMD,
      },
      {
        title: 'close',
        href: '/docs/widget-methods/close',
        md: closeMethodMD,
      },
      {
        title: 'authenticate',
        href: '/docs/widget-methods/authenticate',
        md: authenticateMethodMD,
      },
      {
        title: 'setContext',
        href: '/docs/widget-methods/set-context',
        md: setContextMethodMD,
      },
      {
        title: 'setTranscript',
        href: '/docs/widget-methods/set-transcript',
        md: setTranscriptMethodMD,
      },
      {
        title: 'addEventListener',
        href: '/docs/widget-methods/add-event-listener',
        md: addEventListenerMethodMD,
      },
      {
        title: 'removeEventListener',
        href: '/docs/widget-methods/remove-event-listener',
        md: removeEventListenerMethodMD,
      },
      {
        title: 'removeAllListeners',
        href: '/docs/widget-methods/remove-all-listeners',
        md: removeAllListenersMethodMD,
      },
      {
        title: 'resetState',
        href: '/docs/widget-methods/reset-state',
        md: resetStateMethodMD,
      },
      {
        title: 'resetAuth',
        href: '/docs/widget-methods/reset-auth',
        md: resetAuthMethodMD,
      },
    ],
  },
  {
    title: 'Endpoints',
    icon: <EndpointsIcon />,
    href: '/docs/endpoints',
  },
]
